var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"shadow mt-3",attrs:{"flat":""}},[_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',{staticClass:"p-0"},[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fi fi-rr-settings-sliders")]),_c('h4',[_vm._v("فلاتر")])],1),_c('v-spacer')],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-row',{staticClass:"align-center px-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.$global.state.provinces,"item-text":"name","item-value":"id","clearable":"","dense":"","placeholder":"المحافظة","no-data-text":"لا توجد بيانات","hide-details":"","outlined":""},on:{"input":function($event){return _vm.getBranches(
                                        1,
                                        _vm.filter.pageSize,
                                        _vm.filter.search,
                                        _vm.filter.provinceId
                                    )}},model:{value:(_vm.filter.provinceId),callback:function ($$v) {_vm.$set(_vm.filter, "provinceId", $$v)},expression:"filter.provinceId"}})],1)],1)],1)]),_c('v-divider',{class:_vm.show ? 'mt-3' : 'mt-1'})],1),_c('v-row',{staticClass:"pa-3",attrs:{"align":"center"}},[_c('v-col',{staticClass:"mb-sm-1",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" اضافة فرع جديد ")],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-text-field',{staticClass:"pl-3",attrs:{"placeholder":"ابحث هنا","hide-details":"","dense":"","outlined":""},on:{"input:2000ms":function($event){return _vm.getBranches(
                                1,
                                _vm.filter.pageSize,
                                _vm.filter.search,
                                _vm.filter.provinceId
                            )}},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-select',{staticStyle:{"max-width":"85px"},attrs:{"height":42,"items":_vm.pageSizes,"no-data-text":"لا توجد بيانات","hide-details":"","outlined":"","dense":""},on:{"input:2000ms":function($event){return _vm.getBranches(
                                1,
                                _vm.filter.pageSize,
                                _vm.filter.search,
                                _vm.filter.provinceId
                            )}},model:{value:(_vm.filter.pageSize),callback:function ($$v) {_vm.$set(_vm.filter, "pageSize", $$v)},expression:"filter.pageSize"}})],1)])],1)],1),_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-data-table',{staticClass:"mytable",attrs:{"items":_vm.branches,"loading":_vm.loading,"headers":_vm.headers,"search":_vm.filter.search,"hide-default-footer":"","loading-text":"جاري تحميل البيانات","no-data-text":"لا توجد بيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light',"items-per-page":_vm.filter.pageSize},scopedSlots:_vm._u([{key:`item.id`,fn:function({ item }){return [_c('h5',[_vm._v(_vm._s(_vm.branches.indexOf(item) + 1))])]}},{key:`item.name`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold text-decoration-underline"},[_vm._v(_vm._s(item.name))])]}},{key:`item.isActive`,fn:function({ item }){return [(item.isActive)?_c('v-chip',{staticClass:"rounded-tl-xl rounded-br-xl chip-success",attrs:{"small":""}},[_vm._v("فعال")]):_vm._e(),(!item.isActive)?_c('v-chip',{staticClass:"rounded-tl-xl rounded-br-xl chip-warning",attrs:{"small":""}},[_vm._v("محظور")]):_vm._e()]}},{key:`item.createdAt`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))])]}},{key:`item.actions`,fn:function({ item, index }){return [_c('div',{staticStyle:{"min-width":"75px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('edit',_vm._g({attrs:{"branch":item},on:{"editBranch":function($event){return _vm.getBranches(
                                            _vm.filter.pageIndex,
                                            _vm.filter.pageSize,
                                            _vm.filter.search,
                                            _vm.filter.provinceId
                                        )}}},on))],1)]}}],null,true)},[_c('span',[_vm._v("تعديل")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","icon":"","depressed":""},on:{"click":function($event){return _vm.deleteItem(item.id, index)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fi fi-rr-trash")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-space-between py-3"},[_c('v-spacer'),_c('v-pagination',{attrs:{"length":_vm.length,"total-visible":7},on:{"input":function($event){return _vm.getBranches(
                    _vm.filter.pageIndex,
                    _vm.filter.pageSize,
                    _vm.filter.search,
                    _vm.filter.provinceId
                )}},model:{value:(_vm.filter.pageIndex),callback:function ($$v) {_vm.$set(_vm.filter, "pageIndex", $$v)},expression:"filter.pageIndex"}})],1),_c('v-divider'),_c('Dialog')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }