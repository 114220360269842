<template>
    <div>
        <v-card flat class="shadow mt-3">
            <!-- header -->
            <div>
                <!-- filters -->
                <v-card flat class>
                    <v-card-actions class="p-0">
                        <v-btn small text @click="show = !show">
                            <v-icon small left>fi fi-rr-settings-sliders</v-icon>
                            <h4>فلاتر</h4>
                        </v-btn>
                        <v-spacer />
                    </v-card-actions>

                    <v-expand-transition>
                        <div v-show="show">
                            <v-row class="align-center px-3">
                                <v-col cols="12" md="3">
                                    <v-autocomplete @input="
                                        getBranches(
                                            1,
                                            filter.pageSize,
                                            filter.search,
                                            filter.provinceId
                                        )
                                        " :items="$global.state.provinces" v-model="filter.provinceId" item-text="name" item-value="id"
                                        clearable dense placeholder="المحافظة" no-data-text="لا توجد بيانات" hide-details
                                        outlined />
                                </v-col>
                            </v-row>
                        </div>
                    </v-expand-transition>
                    <v-divider :class="show ? 'mt-3' : 'mt-1'" />
                </v-card>
                <v-row align="center" class="pa-3">
                    <v-col cols="12" md="2" class="mb-sm-1">
                        <v-btn @click="openAddDialog()" color="primary" class="btn">
                            <v-icon small class="pl-1">mdi-plus</v-icon>
                            اضافة فرع جديد
                        </v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="12" md="4">
                        <div class="d-flex justify-space-between">
                            <v-text-field @input:2000ms="
                                getBranches(
                                    1,
                                    filter.pageSize,
                                    filter.search,
                                    filter.provinceId
                                )
                                " v-model="filter.search" placeholder="ابحث هنا" hide-details dense class="pl-3" outlined />
                            <v-select @input:2000ms="
                                getBranches(
                                    1,
                                    filter.pageSize,
                                    filter.search,
                                    filter.provinceId
                                )
                                " :height="42" v-model="filter.pageSize" :items="pageSizes" no-data-text="لا توجد بيانات"
                                style="max-width: 85px" hide-details outlined dense>
                            </v-select>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <!-- table -->
            <v-card flat tile>
                <v-data-table class="mytable" :items="branches" :loading="loading" :headers="headers"
                    :search="filter.search" hide-default-footer loading-text="جاري تحميل البيانات"
                    no-data-text="لا توجد بيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
                    :items-per-page="filter.pageSize">
                    <template v-slot:[`item.id`]="{ item }">
                        <h5>{{ branches.indexOf(item) + 1 }}</h5>
                    </template>

                    <template v-slot:[`item.name`]="{ item }">
                        <span class="font-weight-bold text-decoration-underline">{{
                            item.name
                        }}</span>
                    </template>

                    <template v-slot:[`item.isActive`]="{ item }">
                        <v-chip small class="rounded-tl-xl rounded-br-xl chip-success" v-if="item.isActive">فعال</v-chip>
                        <v-chip small class="rounded-tl-xl rounded-br-xl chip-warning" v-if="!item.isActive">محظور</v-chip>
                    </template>

                    <template v-slot:[`item.createdAt`]="{ item }">
                        <span>{{ item.createdAt | formatDate }}</span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item, index }">
                        <div style="min-width: 75px">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon>
                                        <edit v-on="on" :branch="item" @editBranch="
                                            getBranches(
                                                filter.pageIndex,
                                                filter.pageSize,
                                                filter.search,
                                                filter.provinceId
                                            )
                                            "></edit>
                                    </v-btn>
                                </template>
                                <span>تعديل</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="deleteItem(item.id, index)" v-on="on" x-small icon depressed>
                                        <v-icon color="error">fi fi-rr-trash</v-icon>
                                    </v-btn>
                                </template>
                                <span>حذف</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
            <div class="d-flex justify-space-between py-3">
                <v-spacer></v-spacer>
                <v-pagination v-model="filter.pageIndex" :length="length" :total-visible="7" @input="
                    getBranches(
                        filter.pageIndex,
                        filter.pageSize,
                        filter.search,
                        filter.provinceId
                    )
                    "></v-pagination>
            </div>
            <v-divider></v-divider>
            <!-- <Pagination :items="$global.state.branches" /> -->
            <Dialog />
        </v-card>
    </div>
</template>

<script>
import Edit from "./edit.vue";
export default {
    data() {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            show: false,
            headers: [

                {
                    text: "اسم الفرع",
                    value: "name",
                },
                {
                    text: "الوصف",
                    value: "description",
                },
                {
                    text: "رقم الهاتف الاول",
                    value: "phoneNumber1",
                },
                {
                    text: "رقم الهاتف الثاني",
                    value: "phoneNumber2",
                },
                {
                    text: "واتساب",
                    value: "whatsapp",
                },
                {
                    text: "المحافظة",
                    value: "province.name",
                },
                {
                    text: "العنوان الكامل",
                    value: "fullAddress",
                },
                {
                    text: "تاريخ الإضافة",
                    value: "createdAt",
                },
                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
            branches: [],
            loading: false,
            uploading: false,

            length: 0,
            filter: {
                pageIndex: 1,
                pageSize: 15,
                provinceId: null,
                search: "",
                count: 0,
            },
            pageSizes: [5, 10, 15, 30, 50, 100],
        };
    },

    components: {
        Dialog: () => import("./dialog"),
        Edit,
    },

    mounted() {
        this.getItems();
        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.getBranches();
        },
        async getBranches(pageIndex, pageSize, search, provinceId) {
            this.loading = true;
            // console.log(provinceId);
            try {
                let query = `Branches?IsDelete=false`;

                if (pageIndex !== undefined && pageIndex !== null) {
                    query += `&PageIndex=${pageIndex}`;
                }

                if (pageSize !== undefined && pageSize !== null) {
                    query += `&PageSize=${pageSize}`;
                }

                if (provinceId !== undefined && provinceId !== null) {
                    query += `&ProvinceId=${provinceId}`;
                }

                if (search !== undefined && search !== null) {
                    query += `&Search=${search}`;
                }

                const res = await this.$http.get(query);
                this.branches = res.data.result.data;
                this.filter.count = res.data.result.count;
                this.length =
                    res.data.result.count == undefined
                        ? 0
                        : Math.ceil(res.data.result.count / this.filter.pageSize);
            } catch (err) {
                this.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.loading = false;
            }
        },
        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.branches.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = { dialogType: "add" };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>
